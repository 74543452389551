<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-md-12 mt-2">
                <div class="ms-panel ms-panel-fh">
                    <div class="ms-panel-header ms-panel-custom mb-4">
                        <div class="row align-items-center">
                            <div class="col-md-6">
                                <div class="w-25 d-none">
                                    <create-action v-if="$root.$can('bill', 'bill-create')"
                                                   to_name="invoiceCreate"
                                                   title="Invoice"/>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h5 class="border-bottom text-primary border-primary d-inline-block border-2">Invoice
                                    List</h5>
                            </div>

                        </div>
                    </div>
                    <div class="ms-panel-body ms-panel-body-list">
                        <div id="printMe" class="table-responsive">
                            <table class="table table-striped table-hover thead-primary w-100">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Invoice No.</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Patient</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Total Paid</th>
                                    <th scope="col">Total Due</th>
                                    <th scope="col">P. Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-if="transactions.length">
                                    <tr v-for="(transaction, index) in transactions">
                                        <th scope="row">{{ table.pagination.from + index }}</th>
                                        <th scope="row">
                                            <router-link v-if="$root.$can('invoice', 'invoice-view')"
                                                         :to="{ name: 'invoiceView', params: { id: transaction.id }}">
                                                {{ transaction.invoice_no }}
                                            </router-link>
                                        </th>
                                        <td>{{ transaction.transaction_date | dateFormat }}</td>
                                        <td>
                                            <span v-if="transaction.transactionable">
                                                {{ transaction.transactionable.name +' | ' + transaction.transactionable.id_number}}
                                            </span>
                                        </td>
                                        <td>{{ transaction.final_total | numberFormat }}</td>
                                        <td>{{ transaction.total_paid | numberFormat }}</td>
                                        <td>{{ transaction.total_due | numberFormat }}
                                            <label title="Payment Add" v-if="transaction.total_due > 0 "
                                                   @click="openPaymentCreateModal(transaction)"
                                                   class="badge bg-success" type="button">
                                                Add
                                            </label>
                                            |
                                            <label title="Payment List" @click="openPaymentListModal(transaction)"
                                                   class="badge bg-primary" type="button">
                                                List
                                            </label>
                                        </td>
                                        <td>
                                            <label :class="`payment-css-${transaction.payment_status}`"
                                                   class="badge text-capitalize">
                                                {{ transaction.payment_status }}
                                            </label>
                                        </td>
                                        <td>
                                            <div class="d-flex gap-1">
                                                <print-action type="modal" :id="transaction.id"/>
                                                <view-action v-if="$root.$can('bill', 'bill-view')"
                                                             to_name="invoiceView" :id="transaction.id"/>
                                                <update-action v-if="$root.$can('bill', 'bill-update')"
                                                               to_name="invoiceEdit" :params="{ id: transaction.id }"/>
                                                <delete-action class="w-auto"
                                                               v-if="$root.$can('bill', 'bill-delete')"
                                                               :id="transaction.id"/>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="!transactions.length">
                                    <tr>
                                        <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <pagination v-if="transactions.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
                    </div>
                </div>
            </div>
            <payment-create-modal ref="payment_create_modal" v-if="payment_create_modal"/>
            <payment-list-modal ref="payment_list_modal" v-if="payment_list_modal"/>
            <InvoicePrintView ref="print_view" v-if="print_view"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import PaymentCreateModal  from "./payment/modal/Create";
import PaymentListModal    from "./payment/modal/List";
import Pagination          from "@/components/Pagination";
import InvoicePrintView    from "./PrintView";
import DateRangePicker     from "@/components/picker/DateRangePicker";
import moment              from "moment";

export default {
    name      : "Index",
    components: {InvoicePrintView, Pagination, PaymentListModal, PaymentCreateModal, DateRangePicker},
    data      : () => ({
        payment_create_modal: false,
        print_view          : false,
        print_transaction   : {},
        payment_list_modal  : false,
        transaction_payments: [],
        transactions        : [],
        table               : {
            per_page      : 10,
            search        : '',
            pagination    : {
                current_page: 1
            },
            date_range    : [
                moment(new Date()).format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
            ],
            doctor        : '',
            patient       : '',
            type          : '',
            payment_status: '',
        },
        pagination          : {
            current_page: 1,
        },
    }),
    mounted() {
        this.getList()
    },
    methods: {
        print(invoice_id) {
            this.print_view         = true;
            const component_created = setInterval(() => {
                if (this.$refs['print_view']) {
                    this.$refs['print_view'].getInvoice(invoice_id);
                    clearInterval(component_created);
                }
            }, 100);
        },
        getList() {
            let params = {
                ...this.table,
                page      : this.table.pagination.current_page,
                patient_id: this.$route.query.patient_id,
                doctor_id: this.$route.query.doctor_id,
            };
            ApiService.get('/transactions', {params: params}).then((res) => {
                this.transactions     = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/transactions/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        openPaymentCreateModal(transaction) {
            if (transaction.total_due > 0) {
                this.payment_create_modal = true;
                const component_created   = setInterval(() => {
                    if (this.$refs['payment_create_modal']) {
                        let payment_form                            = {
                            transaction_id: transaction.id,
                            method        : 'cash',
                            amount        : transaction.total_due,
                            max_amount    : transaction.total_due,
                            paid_on       : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                        };
                        this.$refs['payment_create_modal'].editMode = false;
                        this.$refs['payment_create_modal'].form     = payment_form;
                        this.$refs['payment_create_modal'].openPaymentModal();
                        clearInterval(component_created);
                    }
                }, 100);
            } else {
                NotificationService.success('Payment Completed, Thanks');
            }
        },

        openPaymentListModal(transaction) {
            this.payment_list_modal = true;
            const component_created = setInterval(() => {
                if (this.$refs['payment_list_modal']) {
                    this.transaction_payments = transaction.transaction_payments;
                    this.$refs['payment_list_modal'].openPaymentModal();
                    clearInterval(component_created);
                }
            }, 100);
        }
    }
}
</script>
<style scoped>
.payment-css-paid {
    color: #fff;
    background-color: #28a745;
}

.payment-css-partial {
    color: #fff;
    background-color: #ffc107;
}

.payment-css-due {
    color: #fff;
    background-color: #dc3545;
}
</style>
